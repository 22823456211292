<template>
  <div class="ArticleDetail">
    <h4>{{ ArticleData.name }}</h4>
    <!-- 用户信息 -->
    <div class="userInfo">
      <div class="box">
        <div class="photo">
          <img :src="ArticleData.photo_url == '' ? photo : ArticleData.photo_url" alt="" />
        </div>
        <span>{{ ArticleData.username }}</span>
        <span>2020-04-21</span>
        <span>{{
            ArticleData.status == 4 || ArticleData.status == 1
              ? 0
              : ArticleData.view_count
          }}阅读</span>
        <span>{{
            ArticleData.status == 4 || ArticleData.status == 1
              ? 0
              : ArticleData.view_count
          }}点赞</span>
        <span>{{
            ArticleData.status == 4 || ArticleData.status == 1
              ? 0
              : ArticleData.view_count
          }}评论</span>
      </div>
      <div class="df Btnbox" v-if="ArticleData.status == 1">
        <!-- 不通过传参为4 -->
        <div @click="AuditTxt(4)" class="bt un_pass">不通过</div>
        <!-- 通过传参为2 -->
        <div @click="AuditTxt(2)" class="bt">通过</div>
      </div>
    </div>
    <!-- 底部内容盒子 -->
    <div class="contentList rich_text_div_img" v-html="ArticleData.content" v-if="ArticleData.status != 4 || ArticleData.status != 1"></div>

    <!-- //弹框 -->
    <el-dialog title="不通过理由" :visible.sync="dialogFormVisible" style="width: 900px; margin: 0 auto">
      <el-input type="textarea" :rows="6" placeholder="请输入审核不通过的理由" v-model="textarea" resize="none">
      </el-input>
      <div slot="footer" class="dialog-footer">
        <el-button @click="openShow()">取 消</el-button>
        <el-button type="primary" @click="noPass()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      photo: require("../../assets/images/avator.png"),
      ArticleData: "",
      dialogFormVisible: false,
      textarea: "",
    };
  },
  created() {
    //初始化
    this.init();
  },
  computed: {},
  methods: {
    init() {
      //  初始化文章详情
      this.TxtDetail();
    },
    //初始化文章详情

    TxtDetail() {
      let tid = this.$route.query.tid;
      this.$axios
        .get("teachingStudioArticle/details", {
          params: {
            id: tid,
          },
        })
        .then((res) => {
          this.ArticleData = res.data.data;
        });
    },
    //审核 文章
    AuditTxt(sid) {
      if (sid == 2) {
        let ob = {
          id: this.$route.query.tid,
          status: sid,
          teaching_studio_id: localStorage.getItem("studioId"),
          reason: "",
        };
        this.$axios.put("teachingStudioArticle/status", ob).then((res) => {
          this.$message.success("审批通过");
          setTimeout(() => {
            this.$router.go(-1);
          }, 1000);
        });
      } else if (sid == 4) {
        this.openShow();
      }
    },

    //显示弹框
    openShow() {
      this.dialogFormVisible = !this.dialogFormVisible;
      this.textarea = "";
    },

    //审批不通过
    noPass() {
      let ob = {
        id: this.$route.query.tid,
        status: 4,
        teaching_studio_id: localStorage.getItem("studioId"),
        reason: this.textarea,
      };
      this.$axios.put("teachingStudioArticle/status", ob).then(() => {
        this.$message.success("审批不通过");
        this.dialogFormVisible = !this.dialogFormVisible;
        setTimeout(() => {
          this.$router.go(-1);
        }, 1000);
      });
    },
  },
};
</script>
<style lang="less" scoped>
.ArticleDetail {
  padding: 39px 20px;
  h4 {
    margin-bottom: 28px;
  }
  .userInfo {
    display: flex;
    justify-content: space-between;
    .box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        font-size: 16px;
        font-family: Microsoft YaHei Regular, Microsoft YaHei Regular-Regular;
        font-weight: 400;
        text-align: left;
        margin-right: 20px;
        color: #333333;
      }
      .photo {
        width: 28px;
        height: 28px;
        margin-right: 11px;
        img {
          width: 28px;
          height: 28px;
          border-radius: 50%;
        }
      }
    }
    .Btnbox {
      cursor: pointer;
      .bt {
        width: 102px;
        height: 34px;
        line-height: 34px;
        text-align: center;
        background: #ff8201;
        border-radius: 4px;
        font-weight: 600;
        color: #fff;
        margin-left: 14px;
        &.un_pass {
          color: #666;
          background: #f7f7f7;
          border: 1px solid #ececec;
        }
      }
    }
  }
  //内容盒子
  .contentList {
    font-size: 16px;
    font-family: Microsoft YaHei Regular, Microsoft YaHei Regular-Regular;
    font-weight: 400;
    text-align: left;
    color: #333333;
    line-height: 32px;
    margin-top: 18px;
    padding-top: 18px;
    border-top: 1px dashed #ececec;
  }
}
</style>
